import { Scheme, PaymentType, RefundStatus, SessionStatus, TransferState, Origin } from '@app-shared/enums';
import { Beneficiary, PSU } from '@app-shared/models';
import { ConnectSettings } from './connect-settings.model';

export class Payment {
    accountType?: 'internal' | 'internal_acquiring' | 'external';
    amount: string;
    receivedAmount: string;
    numberAmount: number;
    numberReceivedAmount: number;
    beneficiary?: Beneficiary;
    executionDate?: string;
    communication: string;
    currency: string;
    isAccepted?: boolean;
    paymentScheme?: Scheme;
    provider?: string;
    providerPaymentType?: PaymentType;
    initialProviderPaymentType?: PaymentType;
    technicalServiceProviderId?: string;
    psu?: PSU;
    refundAmount?: string;
    refundStatus?: RefundStatus;
    sessionId?: string;
    status?: SessionStatus;
    bankAccountId?: string;
    transferState: TransferState;
    isRetryable: boolean;
    isEligibleIsctFeeRefund: boolean;
    providerRedirectionError?: string;
    providerRedirectionErrorMessage?: string;
    settings: ConnectSettings;

    constructor(obj: any) {
        this.accountType = obj.account_type;
        this.amount = obj.amount;
        this.numberAmount = obj.number_amount;
        this.receivedAmount = obj.received_amount;
        this.numberReceivedAmount = obj.number_received_amount;
        this.executionDate = obj.execution_date;
        this.communication = obj.communication;
        this.currency = obj.currency;
        this.isAccepted = obj.is_accepted;
        this.paymentScheme = obj.payment_scheme;
        this.provider = obj.provider;
        this.providerPaymentType = obj.provider_payment_type;
        this.initialProviderPaymentType = obj.initial_provider_payment_type || obj.provider_payment_type;
        this.technicalServiceProviderId = obj.technical_service_provider_id;
        this.refundAmount = obj.refund_amount;
        this.refundStatus = obj.refund_status;
        this.sessionId = obj.session_id;
        this.status = obj.status;
        this.bankAccountId = obj.bank_account_id;
        this.beneficiary = obj.beneficiary ? new Beneficiary(obj.beneficiary) : undefined;
        this.psu = obj.psu ? new PSU(obj.psu) : undefined;
        this.transferState = obj.transfer_state;
        this.isRetryable = obj.is_retryable;
        this.isEligibleIsctFeeRefund = obj.is_eligible_isct_fee_refund || false;
        this.providerRedirectionError = obj.provider_redirection_error;
        this.providerRedirectionErrorMessage = obj.provider_redirection_error_message;
        this.settings = obj.settings ? new ConnectSettings(obj.settings) : undefined;

        if (this.provider === 'N/a') {
            this.provider = undefined;
        }
        if (this.psu && Object.keys(this.psu)?.length === 0) {
            this.psu = undefined;
        }
    }

    shouldHaveBeneficiary(): boolean {
        return [PaymentType.REQUEST_FOR_PAYOUT, PaymentType.REFUND].includes(this.providerPaymentType);
    }

    shouldHavePsu(): boolean {
        return [PaymentType.RECONCILED_TRANSFER, PaymentType.REQUEST_TO_PAY, PaymentType.RECOVERY].includes(this.providerPaymentType);
    }

    delayedPaymentReady(): boolean {
        return this.isRequestToPay() || this.isInitiallyRequestToPay();
    }

    /**
     * Reconcilled transfer with specific status
     * @returns boolean
     */
    validReconciledTransfer(): boolean {
        return this.isReconciledTransfer() && (this.isPending() || this.isPartial() || this.isOverpaid() || this.isCreated() || this.isCancelled());
    }

    reconciledTransferTrackingReady(origin: Origin): boolean {
        return this.validReconciledTransfer() && (origin === Origin.EMAIL || !this.isPending());
    }

    pisPaymentTrackingReady(): boolean {
        const hasValidType = this.isPayByBank() || this.isRequestToPay() || this.isRecovery();
        const hasValidStatus = (this.isPending() || this.isCreated() || this.isUnsuccessful()) && !this.refundStatus;
        return hasValidType && hasValidStatus;
    }

    refundTrackingReady(): boolean {
        return this.isCreated() || this.isIbanReceived();
    }

    printReady(): boolean {
        return (this.isReconciledTransfer() || this.isRequestToPay()) && !!this.beneficiary?.commercialName;
    }

    commercialNameReady(): boolean {
        return (this.isReconciledTransfer() || this.isRequestToPay()) && !!this.beneficiary?.commercialName;
    }

    hasRedirectionError(): boolean {
        return !!this.providerRedirectionErrorMessage;
    }

    // Session Status

    isOverpaid(): boolean {
        return this.isCreated() && this.transferState === TransferState.OVERPAID;
    }

    isInsufficient(): boolean {
        return this.isPartial() && this.transferState === TransferState.INSUFFICIENT;
    }

    isPartial(): boolean {
        return this.status === SessionStatus.PAYMENT_PARTIAL;
    }

    isPending(): boolean {
        return this.status === SessionStatus.PAYMENT_PENDING;
    }

    isCreated(): boolean {
        return this.status === SessionStatus.PAYMENT_CREATED;
    }

    isCancelled(): boolean {
        return this.status === SessionStatus.PAYMENT_CANCELLED;
    }

    isScaRequired(): boolean {
        return this.status === SessionStatus.SCA_REQUIRED;
    }

    isExpired(): boolean {
        return this.status === SessionStatus.PAYMENT_EXPIRED;
    }

    isUnsuccessful(): boolean {
        return this.status === SessionStatus.PAYMENT_UNSUCCESSFUL;
    }

    isProviderRequired(): boolean {
        return this.status === SessionStatus.PROVIDER_REQUIRED;
    }

    isIbanReceived(): boolean {
        return this.status === SessionStatus.IBAN_RECEIVED;
    }

    isIbanRequired(): boolean {
        return this.status === SessionStatus.IBAN_REQUIRED;
    }

    // Payment Types

    isRequestToPay(): boolean {
        return this.providerPaymentType === PaymentType.REQUEST_TO_PAY;
    }

    isInitiallyRequestToPay(): boolean {
        return this.initialProviderPaymentType === PaymentType.REQUEST_TO_PAY;
    }

    isRequestForPayout(): boolean {
        return this.providerPaymentType === PaymentType.REQUEST_FOR_PAYOUT;
    }

    isPayByBank(): boolean {
        return this.providerPaymentType === PaymentType.PAY_BY_BANK;
    }

    isInitiallyPayByBank(): boolean {
        return this.initialProviderPaymentType === PaymentType.PAY_BY_BANK;
    }

    isReconciledTransfer(): boolean {
        return this.providerPaymentType === PaymentType.RECONCILED_TRANSFER;
    }

    isRecovery(): boolean {
        return this.providerPaymentType === PaymentType.RECOVERY;
    }

    isRefund(): boolean {
        return this.providerPaymentType === PaymentType.REFUND;
    }

    isAnyBnpl(): boolean {
        return [PaymentType.BUY_NOW_PAY_LATER, PaymentType.BUY_NOW_PAY_LATER_COLLECTOR].includes(this.providerPaymentType);
    }

    isBNPLCollector(): boolean {
        return this.providerPaymentType === PaymentType.BUY_NOW_PAY_LATER_COLLECTOR;
    }

    isInitiallyBNPLCollector(): boolean {
        return this.initialProviderPaymentType === PaymentType.BUY_NOW_PAY_LATER_COLLECTOR;
    }

    // Transfer State

    isSent(): boolean {
        return this.transferState === TransferState.SENT;
    }

    isCompleted(): boolean {
        return this.transferState === TransferState.COMPLETED;
    }

    // Payment Scheme

    isSepa(): boolean {
        return this.paymentScheme === Scheme.SEPA;
    }

    isInstantSepa(): boolean {
        return this.paymentScheme === Scheme.INSTANT_SEPA;
    }

    // Getters

    getOverpaidAmount(): number {
        if (!this.isOverpaid()) {
            return 0;
        }

        return this.numberReceivedAmount - this.numberAmount;
    }

    getRemainingAmount(): number {
        if (!this.isInsufficient()) {
            return 0;
        }

        return this.numberAmount - this.numberReceivedAmount;
    }
}
