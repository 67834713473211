import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, EMPTY, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { ApiUrlsService } from '@app-shared/constants';

import {
    Provider,
    PSU,
    Beneficiary,
    Initiate,
    PSUCompanyInfo,
    Cover,
    Order,
    OcrRefund,
    Payment,
    BnplConnect,
    CoverageDueDate,
    KyuCompany,
    BnplConnectMeta,
    Onboarding,
} from '@app-shared/models';
import { PAYABLE_STATUS } from '@app-shared/constants';
import { unsubscribeOnDestroy } from '@app-shared/decorators';
import { CountryService, DataCollectingService, FileService, LanguageService, LoaderService, RedirectService, StoreService } from '@app-shared/services';
import { ConnectReqSource, DataCollectingEvent, Scope, TransferType, VerificationMethod } from '@app-shared/enums';
import { KyuCompanySearch } from '@app-shared/models/kyu-company-search.model';
import { ChildrenProviders, ConnectService } from '@main/services';
import { TranslateService } from '@ngx-translate/core';
import { PaymentCallback } from '@payment/models';
import { AlertService } from '@app-shared/modules/alert/services/alert.service';
import { INCLUDE_DEVICE_FINGERPRINT } from '../../interceptors/device-fingerprint/device-fingerprint.interceptor';

@Injectable({ providedIn: 'root' })
export class ApiService {
    private headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private countryService: CountryService,
        private languageService: LanguageService,
        private translateService: TranslateService,
        private sanitizer: DomSanitizer,
        private store: StoreService,
        private connectService: ConnectService,
        private fileService: FileService,
        private redirectService: RedirectService,
        private alertService: AlertService,
        private loaderService: LoaderService,
        private dataCollectingService: DataCollectingService,
        private apiUrlsService: ApiUrlsService,
    ) {
        this.initHeaders();
    }

    private initHeaders(): void {
        this.headers = new HttpHeaders().set('x-country', this.countryService.getCurrentCountry()).set('x-language', this.languageService.getLanguage());
        this.handleLanguageChanges();
        this.handleCountryChanges();
    }

    @unsubscribeOnDestroy
    private handleLanguageChanges(): Subscription {
        return this.languageService.getCurrentLanguageChanges().subscribe((language) => {
            this.headers = this.headers.set('x-language', language);
        });
    }

    @unsubscribeOnDestroy
    private handleCountryChanges(): Subscription {
        return this.countryService.getCurrentCountryChanges().subscribe((country) => {
            if (country) {
                this.headers = this.headers.set('x-country', country);
            }
        });
    }

    getProviders(params?: { swift_bic?: string }): Observable<Array<ChildrenProviders>> {
        const hasReconciledTransferFeature = this.connectService.get().hasReconciledTransferFeature();
        const queryParams = {
            ...params,
            ...(hasReconciledTransferFeature && { with_sepa_providers: true }),
        };
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http
                    .get(`${API_URLS.get_providers}/${connectId}`, {
                        headers: this.headers,
                        params: queryParams,
                    })
                    .pipe(
                        map(
                            (providers): Array<ChildrenProviders> =>
                                Object.values(providers).map((subProviders) =>
                                    subProviders.map(
                                        (subProvider) =>
                                            (subProvider = new Provider(
                                                Object.assign({
                                                    ...subProvider,
                                                    logo_url: subProvider.logo_url
                                                        ? this.sanitizer.bypassSecurityTrustResourceUrl(subProvider.logo_url)
                                                        : subProvider.logo_url,
                                                }),
                                            )),
                                    ),
                                ),
                        ),
                    ),
            ),
        );
    }

    getProvider(providerId: string, technicalServiceProviderId?: string): Observable<Provider> {
        const params = technicalServiceProviderId && { technical_service_provider_id: technicalServiceProviderId };
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http
                    .get(`${API_URLS.get_providers}/${connectId}/provider/${providerId}`, {
                        headers: this.headers,
                        params,
                    })
                    .pipe(map((result) => new Provider(result))),
            ),
        );
    }

    /**
     * Initiate payment with the selected provider
     * @param body - required info
     * @param {ConnectReqSource} [query.source] - (optional) source of flow calling this endpoint (e.g. 'web2app'), used by backend for metrics logging purposes
     * @returns
     */
    initPayment(
        body: { provider: Provider; psu?: PSU; beneficiary?: Beneficiary; shareCart?: boolean; debtorIban?: string },
        query: { source?: ConnectReqSource } = {},
    ): Observable<Initiate> {
        if (!body?.provider) {
            return EMPTY;
        }
        this.loaderService.set({ loading: true, bankName: body.provider.fullName, error: false });
        const source = query?.source;
        const queryParams = { ...(source && { source }), technical_service_provider_id: body.provider.technicalServiceProviderId };
        const API_URLS = this.apiUrlsService.getUrls();

        return this.getPayment(queryParams).pipe(
            switchMap((payment: Payment) => {
                if (PAYABLE_STATUS.includes(payment.status)) {
                    return this.http.post<Initiate>(
                        `${API_URLS.init_payment_url}/${this.store.state.value.connectId}`,
                        { ...body, provider: body.provider.id },
                        { headers: this.headers, params: queryParams, context: new HttpContext().set(INCLUDE_DEVICE_FINGERPRINT, true) },
                    );
                }

                this.connectService.pis(payment);
                return of(null);
            }),
            tap((initiatedObj: Initiate) => {
                if (initiatedObj?.url && source === ConnectReqSource.WEB2APP) {
                    // POST req was made & payment was initiated from web2app flow
                    this.dataCollectingService
                        .sendEvent(DataCollectingEvent.PIS_WEB2APP_REDIRECT_BANK_AREA, { connectId: this.store.state.value.connectId })
                        .subscribe();
                }
            }),
            catchError((e) => {
                if (e.status === 302 && e.error?.url?.includes('/v2/payment/callback')) {
                    // Redirect to callback page (payment init skipped because unallowed/not needed)
                    const redirectUrl = e.error.url;
                    this.redirectService.redirect(redirectUrl, { target: '_self' });
                    return EMPTY;
                }
                console.error(e);
                this.handleInitPaymentError(e, body.provider);
                return EMPTY;
            }),
        );
    }

    handleInitPaymentError(error: HttpErrorResponse, provider: Provider): void {
        this.alertService.close('fees');
        const isProviderError = error?.status === 400 && error?.error?.errors?.[0]?.code === 'PROVIDER_ERROR';
        this.loaderService.set({ error: true, title: isProviderError ? 'provider_error' : 'unavailable', bankName: provider.fullName });
    }

    /**
     * Get payment details
     * @param {object} [query] - optional
     * @param {ConnectReqSource} [query.source] - (optional) source of flow calling this endpoint (e.g. 'web2app'), used by backend for metrics logging purposes
     */
    getPayment(query: { source?: ConnectReqSource } = {}): Observable<Payment> {
        const params = new HttpParams({ fromObject: query });
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http
            .get(`${API_URLS.get_payment}/${this.store.state.value.connectId}`, {
                headers: this.headers,
                params,
            })
            .pipe(map((result) => new Payment(result)));
    }

    getPaymentRedirectUrl(): Observable<string> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http
            .get(`${API_URLS.get_payment_redirection.replace('[[:connectId]]', this.store.state.value.connectId)}`, {
                headers: this.headers,
            })
            .pipe(map((result) => result['url']));
    }

    downloadPaymentPDF(beneficiary: string, communication: string): Observable<any> {
        // Create object from the headers otherwise HttpClient validation may fail with error
        // "Unexpected value of the `normalizedNames` header provided. Expecting either a string, a number or an array, but got: `[object Map]`."
        // Possibly related: https://github.com/angular/angular/issues/49353
        const headers: {
            [key: string]: string;
        } = {};
        this.headers.keys().forEach((key) => {
            headers[key] = this.headers.get(key);
        });
        headers.accept = 'application/pdf';
        headers['accept-language'] = this.languageService.getLanguage();
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http
            .get(`${API_URLS.get_payment}/${this.store.state.value.connectId}`, {
                headers,
                responseType: 'blob',
            })
            .pipe(
                map((data) => {
                    const filename = this.translateService.instant('modules.pis.reconciled-transfer.pdf-filename', { beneficiary, communication });
                    this.fileService.saveFile(`${filename}.pdf`, 'application/pdf', data, '_blank');
                }),
            );
    }

    createManualPayment(psu?: PSU): Observable<Initiate> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http.post<Initiate>(
                    `${API_URLS.create_manual_payment_url}/${connectId}`,
                    {
                        ...(psu && psu?.completed && psu?.email && { psu }),
                    },
                    {
                        headers: this.headers,
                    },
                ),
            ),
        );
    }

    createPaymentFromTemplate(templateConnectId: string, psu: PSU): Observable<Initiate> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http.post<Initiate>(
            `${API_URLS.create_payment_from_template}/${templateConnectId}`,
            {
                psu_data: { psu_name: psu.name, psu_email: psu.email },
            },
            {
                headers: this.headers,
            },
        );
    }

    authorizeAisWithQueryParams(providerId: string, accountId: string, bankBranch: string, technicalServiceProviderId: string): Observable<Initiate> {
        const params = {
            'psu_provider_fields[bank_branch]': bankBranch,
            'psu_provider_fields[account_id]': accountId,
            technical_service_provider_id: technicalServiceProviderId,
        };
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http.get<Initiate>(`${API_URLS.authorize_ais}/${providerId}/authorize/${connectId}`, {
                    context: new HttpContext().set(INCLUDE_DEVICE_FINGERPRINT, true),
                    params,
                }),
            ),
        );
    }

    authorizeAis(providerId: string, technicalServiceProviderId: string): Observable<Initiate> {
        const context = new HttpContext().set(INCLUDE_DEVICE_FINGERPRINT, true);
        const params = { technical_service_provider_id: technicalServiceProviderId };
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http.get<Initiate>(`${API_URLS.authorize_ais}/${providerId}/authorize/${connectId}`, {
                    context,
                    params,
                }),
            ),
        );
    }

    getBnplCompanyCoverage(companyId: string): Observable<CoverageDueDate> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http
            .post(`${API_URLS.get_net_terms_company_coverage.replace('[[:companyId]]', companyId)}`, {})
            .pipe(map((result) => new CoverageDueDate(result)));
    }

    updatePsuInfo(psuCompanyInfo: PSUCompanyInfo): Observable<void> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http.put<void>(`${API_URLS.update_psu_company_info}`, psuCompanyInfo, {
                    headers: {
                        connect_id: connectId,
                    },
                }),
            ),
        );
    }

    createQuote(): Observable<Cover> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http
                    .post<Cover>(
                        `${API_URLS.create_quote}`,
                        {},
                        {
                            headers: this.headers.set('connect_id', connectId),
                        },
                    )
                    .pipe(map((result) => new Cover(result))),
            ),
        );
    }

    activateCover(accountId?: string): Observable<Cover> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http
                    .put<Cover>(
                        `${API_URLS.activate_cover}`,
                        {
                            ...(accountId && { account_id: accountId }),
                        },
                        {
                            headers: {
                                connect_id: connectId,
                            },
                        },
                    )
                    .pipe(map((result) => new Cover(result))),
            ),
        );
    }

    switchSessionToPIS(connectId: string): Observable<BnplConnectMeta> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http
            .post<BnplConnectMeta>(`${API_URLS.switch_session_to_pis}`, {
                meta: {
                    connect_id: connectId,
                },
            })
            .pipe(
                tap((response) => {
                    if (response?.sessionId) {
                        // req successful, update transfer type to ImmediateTransfer (PIS fallback)
                        this.store.state.update({ transferType: TransferType.IMMEDIATE_TRANSFER });
                    }
                }),
                map((result) => result),
            );
    }

    getRejectedResult(connectId: string): Observable<Order> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http.get(`${API_URLS.get_rejected_result}/${connectId}/result`).pipe(map((result) => new Order(result)));
    }

    cancelBnplPayment(connectId: string): Observable<Cover> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http.put<Cover>(API_URLS.get_rejected_result, {}, { params: { is_cancelled: true }, headers: this.headers.set('connect_id', connectId) });
    }

    getOCRRefund(extension: string, file: string): Observable<OcrRefund> {
        const API_URLS = this.apiUrlsService.getUrls();

        const context = new HttpContext().set(INCLUDE_DEVICE_FINGERPRINT, true);
        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http.post<OcrRefund>(API_URLS.ocr_refund, {
                    connect_id: connectId,
                    extension,
                    file,
                    context,
                }),
            ),
        );
    }

    initiateOnboardingForRefund(connectId: string, verificationMethod: VerificationMethod): Observable<Onboarding> {
        const API_URLS = this.apiUrlsService.getUrls();
        return this.http
            .post(`${API_URLS.initiate_onboarding_refund}`.replace('[[:connectId]]', connectId), {
                data: { verification_method: verificationMethod },
            })
            .pipe(map((result) => new Onboarding(result)));
    }

    shareCart(): Observable<void> {
        const API_URLS = this.apiUrlsService.getUrls();
        return this.store.state.selectOnce('connectId').pipe(switchMap((connectId: string) => this.http.put<void>(`${API_URLS.share_cart}/${connectId}`, {})));
    }

    updateScope(scope: Scope): Observable<void> {
        const sessionId = this.store.state.value.sessionId;
        const paymentType = this.store.state.value.paymentType;
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http.patch<void>(`${API_URLS.sessions_scope.replace('[[:sessionId]]', sessionId)}`, { scope, payment_type: paymentType }).pipe(
            tap(() => {
                this.store.state.update({ scope });
            }),
        );
    }

    createBnplSession(connectId: string): Observable<BnplConnect> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http.post<BnplConnect>(API_URLS.create_bnpl_payment, {
            meta: {
                connect_id: connectId,
            },
        });
    }

    createKyuCompany(psuRegistrationNumber: string, connectId: string): Observable<KyuCompany> {
        const body = {
            data: {
                type: 'companies',
                attributes: {
                    incorporation: psuRegistrationNumber,
                },
            },
        };
        const API_URLS = this.apiUrlsService.getUrls();

        return this.http.post<KyuCompany>(API_URLS.kyu_create_company, body, {
            headers: this.headers.set('connect_id', connectId),
        });
    }

    searchKyuCompany(registrationNumber: string): Observable<KyuCompanySearch> {
        const API_URLS = this.apiUrlsService.getUrls();

        return this.store.state.selectOnce('connectId').pipe(
            switchMap((connectId: string) =>
                this.http
                    .get(API_URLS.kyu_search_company, {
                        headers: this.headers.set('connect_id', connectId),
                        params: { registration_number: registrationNumber },
                    })
                    .pipe(map((result) => new KyuCompanySearch(result))),
            ),
        );
    }

    refreshPaymentDetails(sessionId: string): Observable<PaymentCallback> {
        const API_URLS = this.apiUrlsService.getUrls();

        const url = new URL(API_URLS.get_result_url);
        url.searchParams.set('session_id', sessionId);
        const context = new HttpContext().set(INCLUDE_DEVICE_FINGERPRINT, true);
        return this.http
            .get(url.href, {
                headers: new HttpHeaders().set('x-language', this.languageService.getLanguage()),
                context,
            })
            .pipe(map((result) => new PaymentCallback(result)));
    }
}
