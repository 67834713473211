import { Injectable } from '@angular/core';
import { State } from '@app-shared/libs';
import { AlertComponent } from '../alert.component';

interface AlertState {
    current: AlertComponent;
    register: AlertComponent[];
}

@Injectable({ providedIn: 'root' })
export class AlertService {
    readonly state: State<AlertState>;
    private initialState: AlertState = {
        current: null,
        register: [],
    };

    constructor() {
        this.state = new State<AlertState>(this.initialState);
    }

    register(alert: AlertComponent): void {
        const register = this.state.value.register;

        if (!register.find((registerAlert) => registerAlert.identifier === alert.identifier)) {
            register.push(alert);
            this.state.update({ register });
        }
    }

    unregister(alert: AlertComponent): void {
        const register = this.state.value.register;
        const foundedAlertIndex = register.findIndex((registerAlert) => registerAlert.identifier === alert.identifier);
        register.splice(foundedAlertIndex, 1);
        this.state.update({ register, current: null });
    }

    open(id: string): void {
        this.closeCurrent();

        const foundedAlert = this.state.value.register.find((alert) => alert.identifier === id);

        if (foundedAlert) {
            this.state.update({ current: foundedAlert });
        }
    }

    close(id: string): void {
        const foundedAlert = this.state.value.register.find((alert) => alert.identifier === id && alert.showing);

        if (foundedAlert) {
            foundedAlert.hide();
            this.state.update({ current: null });
        }
    }

    closeCurrent(): void {
        if (!this.state.value.current) {
            return;
        }

        this.state.value.current.hide();
        this.state.update({ current: null });
    }
}
