import { OnboardingStatus, VerificationMethod } from '@app-shared/enums';

export class Onboarding {
    id: string;
    status: OnboardingStatus;
    verificationMethod: VerificationMethod;
    redirectUrl: string;
    withExitConfirmation: boolean;
    relatedRedirectLink?: string;

    constructor(obj: any) {
        this.id = obj.data?.id;
        this.status = obj.data?.status;
        this.verificationMethod = obj.data?.verification_method;
        this.redirectUrl = obj.data?.links?.next;
        this.withExitConfirmation = obj.data?.with_exit_confirmation;
        this.relatedRedirectLink = obj.data?.links?.related?.href;
    }

    isUninitiated(): boolean {
        return this.status === OnboardingStatus.UNINITIATED;
    }

    isPending(): boolean {
        return this.status === OnboardingStatus.PENDING;
    }

    isFailed(): boolean {
        return this.status === OnboardingStatus.FAILED;
    }

    isCompleted(): boolean {
        return this.status === OnboardingStatus.COMPLETED;
    }
}
