export enum Functionality {
    SHARE_LINK = 'shareLink',
    CLASSIC_BANK_TRANSFER = 'classicBankTransfer',
    DELAYED_PAYMENT = 'DelayedPayment',
    PAYOUT_METHOD_SELECTOR = 'PayoutMethodSelector',
    OTP_AIS_VERIFICATION = 'otp_ais_verification',
    OTP_OCR_VERIFICATION = 'otp_ocr_verification',
    PAYER_PORTAL_ISCT_FEE_REFUND = 'payer_portal_isct_fee_refund',
    ISCT_FEE_REFUND_BANNER = 'isct_fee_refund_banner',
    PAYOUT_VERIFIED_BY_BANK_TRANSFER = 'payout_verified_by_bank_transfer',
}
