import { Functionality, IsctPolicy, PaymentMethodId, PaymentMethodPlacement, PaymentType, Scope, TransferType } from '@app-shared/enums';
import { Application, BnplSession, Order, Payment, Session, Onboarding, Kyu, SurveyConfig } from '@app-shared/models';
import { PaymentMethod } from '@app-shared/models';
import { ConnectSettings } from './connect-settings.model';

export class Connect {
    application?: Application;
    paymentDetails?: Payment;
    sessionDetails?: Session;
    bnplSession?: BnplSession;
    order?: Order;
    expiresAt: string;
    expiryDate?: string;
    kyu?: Kyu;
    onboarding?: Onboarding;
    surveys?: SurveyConfig;
    settings?: ConnectSettings;

    constructor(obj: any) {
        this.application = obj.application ? new Application(obj.application) : undefined;
        this.paymentDetails = obj.payment_details ? new Payment(obj.payment_details) : undefined;
        this.sessionDetails = obj.session_details ? new Session(obj.session_details) : undefined;
        this.bnplSession = obj.bnpl_session ? new BnplSession(obj.bnpl_session) : undefined;
        this.order = obj.order ? new Order(obj.order) : undefined;
        this.expiryDate = obj.expiryDate;
        // Note: expiresAt is stored in a different field as expiryDate because expiryDate is checked to redirect instantly to "expired link" page if defined
        this.expiresAt = obj.expires_at; // connect_session.expiry_date, used for ST expiry-date alert banner
        this.kyu = obj.kyu ? new Kyu(obj.kyu) : undefined;
        this.onboarding = obj.onboarding ? new Onboarding(obj.onboarding) : undefined;
        this.surveys = obj.surveys ? new SurveyConfig(obj.surveys) : undefined;
        this.settings = obj.settings ? new ConnectSettings(obj.settings) : undefined;

        if (this.isSessionExpired() && !this.expiryDate) {
            this.expiryDate = new Date().toISOString();
        }
        if (this.sessionDetails?.originUri) {
            if (this.sessionDetails?.state) {
                this.sessionDetails.originUri.searchParams.append('state', this.sessionDetails.state);
            }

            if (this.sessionDetails?.sessionId) {
                this.sessionDetails.originUri.searchParams.append('session_id', this.sessionDetails.sessionId);
            }

            this.sessionDetails.originUri.searchParams.append('status', this.paymentDetails?.status);
        }
    }

    hasFunctionality(functionality: Functionality): boolean {
        return this.application?.hasApplicationFunctionality(functionality);
    }

    hasIsctFeeRefund(): boolean {
        return (
            [IsctPolicy.ONLY_ISCT, IsctPolicy.PRIORITY_ISCT].includes(this.application?.isctPolicy) &&
            this.hasFunctionality(Functionality.PAYER_PORTAL_ISCT_FEE_REFUND) &&
            this.hasFunctionality(Functionality.ISCT_FEE_REFUND_BANNER)
        );
    }

    // Case for ADEO
    hasInstFeeRefund(): boolean {
        return this.application?.hasInstFeeRefunds;
    }

    hasVbbtFeature(): boolean {
        return this.isOnboardingRedirectUrl() || this.hasFunctionality(Functionality.PAYOUT_VERIFIED_BY_BANK_TRANSFER);
    }

    hasReconciledTransferFeature(): boolean {
        const hasSmartTransferMethod = this.application?.hasMethod(PaymentMethodId.SMART_TRANSFER);
        const hasValidPaymentType = [PaymentType.RECONCILED_TRANSFER, PaymentType.PAY_BY_BANK, PaymentType.REQUEST_TO_PAY].includes(
            this.paymentDetails?.providerPaymentType,
        );
        return hasSmartTransferMethod && hasValidPaymentType;
    }

    hasDelayedPaymentFeature(): boolean {
        return this.paymentDetails?.delayedPaymentReady() && this.hasFunctionality(Functionality.DELAYED_PAYMENT);
    }

    hasPsuIdentityVerificationFeature(scope: Scope = Scope.AIS): boolean {
        let requiredFunctionality: Functionality;
        switch (scope) {
            case Scope.AIS:
                requiredFunctionality = Functionality.OTP_AIS_VERIFICATION;
                break;
            case Scope.OCR:
                requiredFunctionality = Functionality.OTP_OCR_VERIFICATION;
                break;
            default:
                return false;
        }
        return this.paymentDetails?.psu?.identifier && this.hasFunctionality(requiredFunctionality);
    }

    hasShareLinkFeature(): boolean {
        return (
            this.hasFunctionality(Functionality.SHARE_LINK) &&
            (this.paymentDetails?.isRequestToPay() || this.paymentDetails?.isPayByBank() || this.paymentDetails?.isReconciledTransfer())
        );
    }

    isSessionExpired(): boolean {
        return this.paymentDetails?.isExpired() || this.paymentDetails?.isCancelled() || !!this.expiryDate;
    }

    isNetTermsPisFallback(): boolean {
        return (
            this.sessionDetails?.scope === Scope.PIS &&
            [PaymentType.BUY_NOW_PAY_LATER, PaymentType.REQUEST_TO_PAY].includes(this.paymentDetails?.initialProviderPaymentType) &&
            this.paymentDetails?.providerPaymentType === PaymentType.PAY_BY_BANK
        );
    }

    getPISPaymentMethods(): PaymentMethod[] {
        return this.application.paymentMethods.filter((paymentMethod) => this.isPISPaymentMethod(paymentMethod));
    }

    private isPISPaymentMethod(paymentMethod: PaymentMethod): boolean {
        if (paymentMethod.placements.includes(PaymentMethodPlacement.SELECT)) {
            switch (paymentMethod.transferType) {
                case TransferType.IMMEDIATE_TRANSFER:
                    return true;
                case TransferType.SMART_TRANSFER:
                    return this.hasReconciledTransferFeature();
                case TransferType.BNPL:
                    return this.hasDelayedPaymentFeature();
            }
        }
        return false;
    }

    hasManualTransferFallback(): boolean {
        const method = this.application.paymentMethods.find((paymentMethod) => paymentMethod.transferType === TransferType.SMART_TRANSFER);

        if (!method || !method?.placements?.includes(PaymentMethodPlacement.FALLBACK)) {
            return false;
        }

        return this.hasReconciledTransferFeature();
    }

    isOnboardingRedirectUrl(): boolean {
        // Temporary way to identify AIS sessions within the context of an Onboarding.
        // TODO - change when the AIS connect session/onboarding session link can be retrieved from the backend
        return this.sessionDetails?.redirectUrl?.includes('bank_account_onboarding');
    }

    hasOnboardingExitConfirmation(): boolean {
        return this.onboarding?.withExitConfirmation;
    }
}
