export class Kyu {
    id: string;
    favoriteBic?: string;
    isActive: boolean;
    lastAuthenticatedBic?: string;

    constructor(obj) {
        this.id = obj.id;
        this.favoriteBic = obj.favorite_bic;
        this.isActive = obj.is_active;
        this.lastAuthenticatedBic = obj.last_authenticated_bic;
    }
}
