export enum FlowState {
    OTP = 'otp',
    TRANSFER_TYPE_SELECTION = 'transferTypeSelection',
    REQUEST_PSU_INFORMATIONS = 'requestPsuInformations',
    REQUEST_BENEFICIARY_INFORMATIONS = 'requestBeneficiaryInformations',
    BANK_SELECTION = 'bankSelection',
    BANK_SELECTION_CHILDRENS = 'bankSelectionChidrens', // Not used in pis & ais pages enum, only for card contentState (cgu usecase)
    RECONCILE = 'reconcile',
    DEBTOR_ACCOUNT = 'debtorAccount',
    NO_SCHEDULED_PROVIDER = 'noScheduledProvider',
    BANK_SCREEN = 'bankScreen',
    DISABLED_BANK_SCREEN = 'disabledBankScreen',
    PAYOUT_METHOD_SELECTION = 'payoutMethodSelection',
    AIS_DEUTSCHEBANK = 'aisDeutschebankAccountDetails',
}
