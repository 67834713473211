import { SessionStatus, RefundStatus } from '@app-shared/enums';

export const INVALID_PAYMENT_STATUS = [SessionStatus.PAYMENT_UNSUCCESSFUL, SessionStatus.PAYMENT_ERROR, SessionStatus.IBAN_REQUIRED];
export const INVALID_REFUND_STATUS = [RefundStatus.REFUND_UNSUCCESSFUL];

export const VALID_SESSION_STATUS = [
    SessionStatus.PROVIDER_REQUIRED,
    SessionStatus.SCA_REQUIRED,
    SessionStatus.CONFIRMATION_REQUIRED,
    SessionStatus.PAYMENT_CREATED,
    SessionStatus.PAYMENT_PENDING,
    SessionStatus.IBAN_RECEIVED,
];

export const PAYABLE_STATUS = [
    SessionStatus.SESSION_INITIATED,
    SessionStatus.PROVIDER_REQUIRED,
    SessionStatus.SCA_REQUIRED,
    SessionStatus.PAYMENT_UNSUCCESSFUL,
    SessionStatus.PAYMENT_ERROR,
];

export const VALID_REFUND_STATUS = [
    RefundStatus.REFUND_ACCEPTED,
    RefundStatus.REFUND_ABORTED,
    RefundStatus.REFUND_PENDING,
    RefundStatus.REFUND_CREATED,
    RefundStatus.REFUND_PARTIAL,
];
